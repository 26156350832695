/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button, Divider } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Restaurace"}>
        <SiteHeader />

        <Column className="css-1ijhuvf --style2 --full" anim={""} name={"uvod"} animS={"3"} border={""} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/162/fullscreen_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={null} style={{"maxWidth":1390,"backgroundColor":"rgba(60,30,208,1)"}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"5"} style={{"maxWidth":1425}}>
              
              <Title className="title-box fs--102 w--500 title-box--invert lh--1 mt--10" style={{"maxWidth":1106}} content={"Restaurace"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(28, 132, 237);\">Pizza NuPoo</span>"}>
              </Title>

              <Text className="text-box" content={"Otevírací doba:<br>Po-Út : 10-22:30<br>St-Pá : 10-23:30<br>So : 11-23:30<br>Ne : 11-22:30<br>Tel.: 777 060 676<br>"}>
              </Text>

              <Button className="btn-box" url={"https://nupookrc.cz/pizza-v-akci"} href={"https://nupookrc.cz/pizza-v-akci"} content={"Pizza NuPoo"}>
              </Button>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(32, 132, 232);\">Cukrárna Hájek &amp; Hájková</span>"}>
              </Title>

              <Text className="text-box" content={"Otevírací doba:<br>Po-Pá : 8-19<br>So-Ne : 10-19<br>Tel.: 736 539 864<br>Email.: info@cukrarnahh.cz<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.cukrarna-hajek-hajkova.cz/"} href={"https://www.cukrarna-hajek-hajkova.cz/"} content={"Hájek &amp; Hájková&nbsp;"}>
              </Button>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(32, 137, 241);\">Joshi sushi</span>"}>
              </Title>

              <Text className="text-box" content={"Otevírací doba:<br>Po-Pá : 11-21<br>So-Ne : 12-21<br>Tel.: 725 125 215<br>Email.: krc @joshi.cz<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.joshi.cz/"} href={"https://www.joshi.cz/"} content={"Joshi sushi"}>
              </Button>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(37, 135, 234);\">My food place</span>"}>
              </Title>

              <Text className="text-box" content={"Otevírací doba:<br>Po-Ne : 10:30-22<br>Tel.: 241 711 111<br>Email.: objednavky@myfoodplace.cz<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.myfoodplace.cz/"} href={"https://www.myfoodplace.cz/"} content={"Myfoodplace"}>
              </Button>

              <Divider >
              </Divider>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"<span style=\"color: rgb(41, 138, 235);\">Café pavilon</span>"}>
              </Title>

              <Text className="text-box" content={"Otevírací doba:<br>Po-Pá : 8-18<br>So-Ne : 10-18<br>Email.: cafepavilon@gmail.com<br>"}>
              </Text>

              <Button className="btn-box" url={"https://www.facebook.com/cafepavilon/?ref=page_internal"} href={"https://www.facebook.com/cafepavilon/?ref=page_internal"} content={"Café Pavilon"}>
              </Button>

              <Divider >
              </Divider>

              <Title className="title-box" content={"<span style=\"color: rgb(42, 139, 236);\">Hernička a kavárna Pojď si hrát&nbsp;</span>"}>
              </Title>

              <Text className="text-box" content={"Otevírací doba:<br>Po-Pá : 9-18<br>O víkendech pouze oslavy<br>Tel.: 732 944 952<br>Email.: hernicka.pojdsihrat@gmail.com<br>"}>
              </Text>

              <Button className="btn-box" url={"https://hernickapojdsihrat.cz/"} href={"https://hernickapojdsihrat.cz/"} content={"Pojď si hrát&nbsp;"}>
              </Button>

              <Divider >
              </Divider>

              <Title className="title-box" content={"<span style=\"color: rgb(49, 137, 225);\">Vinotéka Slovácký sklípek&nbsp;</span>"}>
              </Title>

              <Text className="text-box" content={"Otevírací doba:<br>Po-Ne : 16-21<br>Tel.: 734 818 265<br>Email.: info@slovackysklipek.cz&nbsp;<br>"}>
              </Text>

              <Button className="btn-box" url={"https://slovackysklipek.cz/"} href={"https://slovackysklipek.cz/"} content={"Slovácký sklípek&nbsp;"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box" content={"Modrý pavilon"}>
              </Title>

              <Text className="text-box" content={"+420 739 090 844<br>ocmodrypavilon@seznam.cz<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}